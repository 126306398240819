import type { SystemCssProperties } from '@mui/system'
import { createTheme } from '@mui/material/styles'
import globalTheme from '@/lib/ThemeRegistry/theme'

export const stickyTheme = createTheme({
    ...globalTheme,
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                },
            },
        },
    },
})

export const horizontalStickyStyles = (component: 'td' | 'th'): SystemCssProperties => ({
    position: 'sticky',
    zIndex: component === 'th' ? 26 : 25,
    left: 0,
    backgroundColor: 'common.white',
    borderRight: '1px solid',
    borderRightColor: 'divider',
})

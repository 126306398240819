/* eslint-disable @typescript-eslint/ban-types */
import type { Order, TableOrder, OrderBy } from './sorting'
import type TableConfig from '../../lib/types/tableConfigTypes'
import type { MouseEvent } from 'react'
import type { SystemCssProperties, SystemStyleObject } from '@mui/system'
import type { TableBodyProps, ExpandableContentProps, FormatRowTableProps } from './TableBody'
import { ThemeProvider } from '@mui/system'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import Box from '@mui/material/Box'
import MuiTable from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import { stickyTheme } from '../../lib/utils/sticky'
import { isEqualArray, getComparator } from './sorting'
import TableHead from './TableHead'
import LoadingSkeleton from './LoadingSkeleton'
import DefaultTableBody from './TableBody'
import TablePagination from './TablePagination'
import ExpandTableRowButton from './ExpandTableRowButton'

export const defaultRowsPerPage = 15
export const smallerPadding = { padding: '6px 4px 6px 4px !important' }

export type TableProps<T1 extends Object, T2 = null> = {
    config: TableConfig<T1, T2>
    rows: T1[]
    fixedRows?: T1[] | T1[][]
    emptyRowsCount?: number
    size?: 'small' | 'medium'
    emptyValue?: React.ReactNode
    formatRow?:
        | SystemStyleObject
        | ((row: T1, tableProps?: FormatRowTableProps<T1, T2>, index?: number) => SystemStyleObject | undefined)
    getRowKey: (row: T1) => React.Key
    onMouseEnter?: (value: T1, index?: number) => void
    onMouseLeave?: () => void
    onMouseClick?: (value: T1, index?: number) => void
    setOpen?: (value: boolean) => void | undefined
    setInitialValues?: (values: T2) => void | undefined
    showEditAction?: string | undefined
    setShowEditAction?: (value: string, index?: number) => void | undefined
    isLoading?: boolean
    height?: SystemCssProperties['maxHeight']
    body?: React.FC<TableBodyProps<T1, T2>>
    hover?: boolean
    stickyHeader?: boolean
    hideSorting?: boolean
    id?: string
    paginate?: boolean
    customRowsPerPage?: number
    expandableContent?: React.FC<ExpandableContentProps<T1>>
    defaultExpandableContentOpenRow?: number
    noResultsMessage?: string
    isRowExpandable?: (row: T1) => boolean
    hideHeaders?: boolean
    formatTableContainer?: SystemCssProperties
    hideSortIcon?: boolean
    scrollableRef?: React.MutableRefObject<HTMLTableRowElement | null>
    borderCollapse?: 'separate' | 'collapse'
    setTableSortOrder?: (tableOrder: TableOrder<T1>[]) => void
    setSortedCol?: (value: Array<OrderBy<T1>>) => void
    onSort?: (e: MouseEvent<unknown>, property: Array<OrderBy<T1>>) => void
    enableMultiSort?: boolean
    updatedTableSortOrder?: TableOrder<T1>[]
    scrollId?: string
    marginTop?: number
}

const Table = <T1 extends Object, T2 = null>({
    config,
    rows,
    fixedRows,
    emptyRowsCount = 0,
    size = 'small',
    emptyValue = '',
    hideSorting = false,
    formatRow,
    getRowKey,
    onMouseEnter,
    onMouseLeave,
    onMouseClick,
    setOpen,
    setInitialValues,
    showEditAction,
    setShowEditAction,
    isLoading,
    height,
    body: TableBody = DefaultTableBody,
    hover,
    stickyHeader,
    id,
    expandableContent,
    isRowExpandable,
    defaultExpandableContentOpenRow,
    paginate = false,
    customRowsPerPage,
    noResultsMessage = 'No Results Found',
    hideHeaders = false,
    formatTableContainer,
    hideSortIcon = false,
    scrollableRef,
    borderCollapse = 'separate',
    setTableSortOrder,
    setSortedCol,
    onSort,
    enableMultiSort = true,
    updatedTableSortOrder,
    scrollId = 'scroll',
    marginTop = 2,
}: TableProps<T1, T2>): JSX.Element => {
    const {
        EmptyRowComponent,
        fields,
        initialSortBy,
        minWidth,
        initialSortOrder = 'asc',
        sortState,
        setSortState,
        initialSort,
    } = config

    const [tableOrder, setTableOrder] = useState<TableOrder<T1>[]>(
        initialSort ||
            (Array.isArray(initialSortBy) ? initialSortBy : initialSortBy && [initialSortBy])?.map((d) => ({
                orderBy: d,
                order: initialSortOrder,
            })) ||
            []
    )

    const [isLegendVisible, setIsLegendVisible] = useState('')

    const [visibleRows, setVisibleRows] = useState<T1[] | null>(null)
    const [rowsPerPage, setRowsPerPage] = useState(customRowsPerPage || defaultRowsPerPage)
    const [page, setPage] = useState(0)

    // Update the table order through an action in the parent (leaderboards)
    if (updatedTableSortOrder && !enableMultiSort) {
        if (!tableOrder.find((d) => d.orderBy === updatedTableSortOrder[0].orderBy)) {
            setTableOrder(updatedTableSortOrder)
        }
    }

    const handleSort = useCallback(
        (e: MouseEvent<unknown>, property: Array<OrderBy<T1>>) => {
            const equalArray = isEqualArray(
                tableOrder.map((t) => t.orderBy),
                property
            )
            let newTableOrder: TableOrder<T1>[] = []
            if (e.altKey && enableMultiSort) {
                e.preventDefault()
                if (tableOrder.map((d) => d.orderBy).includes(property[0])) {
                    newTableOrder = tableOrder.map((d) => {
                        if (property.includes(d.orderBy))
                            return { orderBy: d.orderBy, order: d.order === 'asc' ? 'desc' : 'asc' }
                        return d
                    })
                } else {
                    newTableOrder = [
                        ...tableOrder,
                        ...property.map((d) => {
                            const sortDescFirst = fields.find((f) => f.sortColumn === d)?.sortDescFirst
                            return { orderBy: d, order: (sortDescFirst ? 'desc' : 'asc') as Order }
                        }),
                    ]
                }
            } else {
                const sortDescFirst = fields.find((f) => f.sortColumn === property[0])?.sortDescFirst
                const isAsc =
                    (equalArray && tableOrder.find((t) => t.orderBy === property[0])?.order === 'asc') ||
                    (sortDescFirst && !tableOrder.find((t) => t.orderBy === property[0]))
                newTableOrder = property.map((d) => ({ orderBy: d, order: (isAsc ? 'desc' : 'asc') as Order }))
            }
            setTableOrder(newTableOrder)
            let sortedRows = [...rows]
            if (tableOrder.length) sortedRows = rows.slice().sort(getComparator(tableOrder))
            const updatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            setVisibleRows(updatedRows)
            //  setTableSortOrder and setSortCol control the tableOrder state in the parent component
            if (setTableSortOrder && newTableOrder.length) {
                setTableSortOrder(newTableOrder)
            }
            if (setSortedCol) setSortedCol(property)

            //  onSort is a callback function executed in the parent when the table is sorted through the headers
            if (onSort) onSort(e, property)
        },
        [tableOrder, enableMultiSort, rows, page, rowsPerPage, setTableSortOrder, setSortedCol, onSort, fields]
    )

    const expand = !!expandableContent
    const [collapsedRowOpenIdx, setCollapsedRowOpenIdx] = useState<number | undefined>(defaultExpandableContentOpenRow)
    const tableFields = useMemo(() => {
        const x = [...fields]
        if (expand)
            x.unshift({
                key: 'expand',
                label: '',
                skeletonStyle: 60,
                format: { width: 30 },
                select: ExpandTableRowButton<T1, T2>({
                    collapsedRowOpenIdx,
                    setCollapsedRowOpenIdx,
                }),
            })
        return x
    }, [fields, expand, collapsedRowOpenIdx, setCollapsedRowOpenIdx])

    useEffect(() => {
        if (rows.length && visibleRows?.length && paginate && rows[0] === visibleRows[0] && page !== 0) {
            let sortedRows = [...rows]
            if (tableOrder.length) sortedRows = rows.slice().sort(getComparator(tableOrder))
            const updatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            setVisibleRows(updatedRows)
        }
    }, [visibleRows, rows, paginate, page, rowsPerPage, tableOrder])

    return (
        <Box
            id={id}
            sx={{ width: '100%', '+ .MuiBox-root': { marginTop }, overflow: stickyHeader ? 'auto' : undefined }}
        >
            {isLoading && <LoadingSkeleton<T1, T2> config={config} />}
            {!isLoading && (
                <>
                    <TableContainer
                        id={scrollId}
                        sx={{ maxHeight: height, position: 'relative', ...formatTableContainer }}
                    >
                        <ThemeProvider theme={stickyTheme}>
                            <MuiTable sx={{ minWidth, borderCollapse }} size={size} stickyHeader={stickyHeader}>
                                <TableHead<T1, T2>
                                    fields={tableFields}
                                    tableOrder={tableOrder}
                                    onSort={handleSort}
                                    isLegendVisible={isLegendVisible}
                                    setIsLegendVisible={setIsLegendVisible}
                                    stickyHeader={stickyHeader}
                                    hideSorting={hideSorting}
                                    sortState={sortState}
                                    setSortState={setSortState}
                                    hideHeaders={hideHeaders}
                                    hideSortIcon={hideSortIcon}
                                />
                                <TableBody
                                    fields={tableFields}
                                    EmptyRowComponent={EmptyRowComponent}
                                    tableOrder={tableOrder}
                                    rows={paginate && visibleRows ? visibleRows : rows}
                                    fixedRows={fixedRows}
                                    emptyRowsCount={emptyRowsCount}
                                    emptyValue={emptyValue}
                                    formatRow={formatRow}
                                    getRowKey={getRowKey}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    onMouseClick={onMouseClick}
                                    setOpen={setOpen}
                                    setInitialValues={setInitialValues}
                                    showEditAction={showEditAction}
                                    setShowEditAction={setShowEditAction}
                                    hover={hover}
                                    collapsedRowOpenIdx={collapsedRowOpenIdx}
                                    expandableContent={expandableContent}
                                    noResultsMessage={noResultsMessage}
                                    isRowExpandable={isRowExpandable}
                                    scrollableRef={scrollableRef}
                                />
                            </MuiTable>
                        </ThemeProvider>
                    </TableContainer>
                    {paginate && (
                        <TablePagination<T1>
                            rowCount={rows.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rows={rows}
                            tableOrder={tableOrder}
                            setRowsPerPage={setRowsPerPage}
                            setPage={setPage}
                            setVisibleRows={setVisibleRows}
                        />
                    )}
                </>
            )}
        </Box>
    )
}

export default Table

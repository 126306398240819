import type { ConditionalKeys } from 'type-fest'
import type { Primitive as SortPrimitive } from 'd3-array'
import { ascending, descending } from 'd3-array'

export type Order = 'asc' | 'desc'

type SortPrimitiveOrNull = SortPrimitive | null | undefined
export type OrderByFunction<T> = (row: T) => SortPrimitiveOrNull
export type OrderByKey<T> = ConditionalKeys<T, SortPrimitiveOrNull>
export type OrderBy<T> = OrderByKey<T> | OrderByFunction<T>
export type TableOrder<T> = { orderBy: OrderBy<T>; order: Order }

export type OrderByValue = SortPrimitive | undefined
export function getOrderByValue<T>(row: T, orderBy: OrderByKey<T> | OrderByFunction<T>): OrderByValue {
    let sortValue: SortPrimitiveOrNull
    if (typeof orderBy === 'function') {
        sortValue = orderBy(row)
    } else {
        sortValue = row[orderBy] as SortPrimitiveOrNull
    }
    return sortValue === null ? undefined : sortValue
}

export function getComparator<T>(tableOrderArray: TableOrder<T>[]): (a: T, b: T) => number {
    return (a, b) => {
        let compareResult = 0
        tableOrderArray.forEach((element) => {
            const aValue = getOrderByValue(a, element.orderBy)
            const bValue = getOrderByValue(b, element.orderBy)
            compareResult ||= element.order === 'desc' ? descending(aValue, bValue) : ascending(aValue, bValue)
        })
        return compareResult
    }
}

export function isEqualArray<T>(arr1: OrderBy<T>[] | undefined, arr2: OrderBy<T>[] | undefined): boolean {
    return !!arr1 && !!arr2 && arr1.length === arr2.length && arr1.every((element, index) => element === arr2[index])
}

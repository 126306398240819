import type TableConfig from '../../lib/types/tableConfigTypes'
import React from 'react'
import MuiTable from '@mui/material/Table'
import Skeleton from '@mui/material/Skeleton'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'

const LoadingSkeleton = <T1, T2>({
    fields,
    loadingSkeleton,
    size = 'small',
}: Pick<TableConfig<T1, T2>, 'fields' | 'size' | 'loadingSkeleton'>) => (
    <TableContainer sx={{ height: loadingSkeleton.height, overflow: 'hidden' }}>
        <MuiTable size={size}>
            <TableHead>
                <TableRow>
                    {fields.map(
                        (field) =>
                            field.skeletonStyle && (
                                <TableCell key={field.key}>
                                    <Skeleton sx={{ width: field.skeletonStyle }} />
                                </TableCell>
                            )
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {Array(loadingSkeleton.numOfRows)
                    .fill('')
                    .map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                            {fields.map(
                                (field) =>
                                    field.skeletonStyle && (
                                        <TableCell key={field.key}>
                                            <Skeleton sx={{ width: field.skeletonStyle }} />
                                        </TableCell>
                                    )
                            )}
                        </TableRow>
                    ))}
            </TableBody>
        </MuiTable>
    </TableContainer>
)

type LoadingSkeletonProps<T1, T2> = {
    config: Pick<TableConfig<T1, T2>, 'fields' | 'size' | 'loadingSkeleton'>
    includeWrapper?: boolean
    marginTop?: number
    stickyHeader?: boolean
}
const LoadingSkeletonWrapper = <T1, T2 = null>({
    config: { fields, size, loadingSkeleton },
    stickyHeader,
    includeWrapper = false,
    marginTop = 2,
}: LoadingSkeletonProps<T1, T2>): JSX.Element => {
    if (!includeWrapper) return <LoadingSkeleton fields={fields} size={size} loadingSkeleton={loadingSkeleton} />

    return (
        <Box sx={{ width: '100%', '+ .MuiBox-root': { marginTop }, overflow: stickyHeader ? 'auto' : undefined }}>
            <LoadingSkeleton fields={fields} size={size} loadingSkeleton={loadingSkeleton} />
        </Box>
    )
}

export default LoadingSkeletonWrapper

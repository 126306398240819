/* eslint-disable @typescript-eslint/ban-types */
import type { SystemStyleObject } from '@mui/system'
import type { TableConfigField, TableProps } from '../../lib/types/tableConfigTypes'
import type { TableOrder } from './sorting'
import React from 'react'
import MuiTableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import TableCell from './TableCell'
import { getComparator } from './sorting'

export type FormatRowTableProps<T1 extends Object, T2> = Pick<TableProps<T1, T2>, 'tableOrder' | 'rows'>
export type ExpandableContentProps<T1 extends Object> = { row: T1 }
export type TableBodyProps<T1 extends Object, T2 = null> = {
    fields: TableConfigField<T1, T2>[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    EmptyRowComponent: React.ComponentType<any> | undefined
    tableOrder: TableOrder<T1>[]
    rows: T1[]
    fixedRows?: T1[] | T1[][]
    emptyRowsCount: number
    emptyValue?: React.ReactNode
    formatRow?:
        | SystemStyleObject
        | ((row: T1, tableProps?: FormatRowTableProps<T1, T2>, index?: number) => SystemStyleObject | undefined)
    getRowKey: (row: T1) => React.Key
    onMouseEnter?: (value: T1, index?: number) => void
    onMouseLeave?: () => void
    onMouseClick?: (value: T1, index?: number) => void
    setOpen?: (value: boolean) => void | undefined
    setInitialValues?: (values: T2) => void | undefined
    showEditAction?: string | undefined
    setShowEditAction?: (value: string) => void | undefined
    noResultsMessage: string
    hover?: boolean
    collapsedRowOpenIdx?: number | undefined
    isRowExpandable?: (row: T1) => boolean
    expandableContent?: React.FC<ExpandableContentProps<T1>>
    scrollableRef?: React.MutableRefObject<HTMLTableRowElement | null>
}

const TableBody = <T1 extends Object, T2 = null>({
    fields,
    EmptyRowComponent,
    tableOrder,
    rows,
    fixedRows,
    emptyRowsCount,
    emptyValue,
    formatRow,
    getRowKey,
    onMouseEnter,
    onMouseLeave,
    onMouseClick,
    setOpen,
    setInitialValues,
    showEditAction,
    setShowEditAction,
    collapsedRowOpenIdx,
    expandableContent: ExpandableContent,
    isRowExpandable,
    hover = true,
    noResultsMessage,
    scrollableRef,
}: TableBodyProps<T1, T2>): JSX.Element => {
    let fixedRowCollection: T1[][] | undefined
    if (fixedRows?.length) {
        fixedRowCollection = Array.isArray(fixedRows[0]) ? (fixedRows as T1[][]) : [fixedRows as T1[]]
    }
    const sortedRows = rows.slice().sort(getComparator(tableOrder))
    const noResults = rows.length === 0 && !fixedRowCollection?.length
    if (noResults) {
        return (
            <MuiTableBody>
                <TableRow>
                    <MuiTableCell sx={{ paddingY: 3 }} colSpan={fields.length}>
                        <Typography
                            sx={{ textAlign: 'center', columnSpan: fields.length }}
                            variant="h6"
                            color="text.secondary"
                        >
                            {noResultsMessage}
                        </Typography>
                    </MuiTableCell>
                </TableRow>
            </MuiTableBody>
        )
    }

    return (
        <MuiTableBody>
            {sortedRows.map((row, i) => {
                const rowFormat =
                    typeof formatRow === 'function' ? formatRow(row, { tableOrder, rows: sortedRows }, i) : formatRow
                const rowKey = getRowKey(row)
                const rowExpandable = isRowExpandable ? isRowExpandable(row) : false

                return (
                    <React.Fragment key={rowKey}>
                        <TableRow
                            sx={{ ...rowFormat, cursor: rowExpandable || onMouseClick ? 'pointer' : undefined }}
                            onMouseEnter={onMouseEnter ? () => onMouseEnter(row, i) : undefined}
                            onMouseLeave={onMouseLeave ? () => onMouseLeave() : undefined}
                            onClick={onMouseClick ? () => onMouseClick(row, i) : undefined}
                            hover={hover}
                            ref={scrollableRef}
                        >
                            {fields.map(({ key, select, format, numeric, header, colSpan, className }, idx, self) => (
                                <TableCell
                                    row={row}
                                    rows={rows}
                                    key={key}
                                    index={i}
                                    select={select}
                                    format={format}
                                    numeric={numeric}
                                    emptyValue={emptyValue}
                                    colSpan={colSpan}
                                    className={className}
                                    borderLeft={
                                        idx > 0 && !!header && !!self[idx - 1].header && header !== self[idx - 1].header
                                    }
                                    tableProps={{
                                        setOpen,
                                        setInitialValues,
                                        showEditAction,
                                        setShowEditAction,
                                        isRowExpandable: rowExpandable,
                                        tableOrder,
                                    }}
                                />
                            ))}
                        </TableRow>
                        {!!ExpandableContent && rowExpandable && i === collapsedRowOpenIdx && (
                            <TableRow
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'divider',
                                }}
                            >
                                <MuiTableCell sx={{ p: 0 }} colSpan={fields.length}>
                                    <ExpandableContent row={row} />
                                </MuiTableCell>
                            </TableRow>
                        )}
                    </React.Fragment>
                )
            })}
            {emptyRowsCount > 0 &&
                EmptyRowComponent &&
                Array.from({ length: emptyRowsCount }, (_, i) => (
                    <EmptyRowComponent key={i} index={i + rows.length + 1} />
                ))}
            {fixedRowCollection?.map((fixed) =>
                fixed
                    .slice()
                    .sort(getComparator(tableOrder))
                    .map((row, i) => {
                        const rowFormat =
                            typeof formatRow === 'function' ? formatRow(row, { tableOrder }, i) : formatRow
                        return (
                            <TableRow
                                key={getRowKey(row)}
                                sx={rowFormat}
                                hover
                                onMouseEnter={onMouseEnter ? () => onMouseEnter(row) : undefined}
                                onMouseLeave={onMouseLeave ? () => onMouseLeave() : undefined}
                            >
                                {fields.map(({ key, select, format, numeric }) => (
                                    <TableCell
                                        row={row}
                                        rows={fixed}
                                        key={key}
                                        index={i}
                                        select={select}
                                        format={format}
                                        numeric={numeric}
                                        emptyValue={emptyValue}
                                        tableProps={{
                                            setOpen,
                                            setInitialValues,
                                            showEditAction,
                                            setShowEditAction,
                                        }}
                                    />
                                ))}
                            </TableRow>
                        )
                    })
            )}
        </MuiTableBody>
    )
}

export default TableBody
